// Add your custom JS here.

// Add your custom JS here.
//import { Modal } from 'bootstrap'
import Modal from 'bootstrap/js/dist/modal'
import Tooltip from 'bootstrap/js/dist/tooltip'



//Wow scroll animations
new WOW().init();


//Imports SWIPER slider
// core version + navigation, pagination modules:
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  Lazy,
  Autoplay,
  EffectFade,
  Keyboard
} from 'swiper';

Swiper.use([Navigation, Pagination, Scrollbar, Keyboard, Lazy, Autoplay, EffectFade]);
//(function ($) {
// init Swiper For Heroes - Shows one image 
const swiper = new Swiper('.swiper', {
  //modules: [Navigation, Pagination],
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  // navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
  // scrollbar: {
  //   el: '.swiper-scrollbar',
  // },
});

// init Swiper For Item sliders - Shows several images 
const swiper_items = new Swiper('.swiper-items', {
  // Optional parameters
  //modules: [Navigation, Pagination, Scrollbar],
  //loop: true,
  slidesPerView: 2,
  spaceBetween: 0, //30,
  //preloadImages: false,
  // Enable lazy loading
  // lazy: true,
  // watchSlidesProgress: true,
  allowSlidePrev: true,
  allowSlideNext: true,
  breakpoints: {
    // when window width is >= 320px
    950: {
      slidesPerView: 4,
      //spaceBetween: 10
    }
  },

  // Navigation arrows 
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true
  },
});
//})(jQuery)

 
//Menu - Main navigation


(function($) {

  //ACF layout Button Group - Show SVG bits on hover
  $('[data-fadein-hover]').each(function(){
    let trgt = $(this).data('fadein-hover');
    $(trgt).css({opacity:0});
    $(this).hover(function(){
      $(trgt).stop().animate({opacity:1},500);
    },function(){
      $(trgt).stop().animate({opacity:0},500);
    });
  });

  //Map controls
  $('.map-button').click(function(e){
    e.preventDefault();
  });
  $('.map-button').hover(function(e){
    e.preventDefault();
    $($(this).data('target')).siblings().removeClass("active");
    $($(this).data('target')).addClass("active");
    $($(this)).addClass("active");
    $($(this)).siblings().removeClass("active");

    let svg_mc = $(".panel-locations__background #"+$(this).data('target').split('content-')[1]);
    svg_mc.addClass('active');
    svg_mc.siblings().removeClass('active');
    
  },function(){

  });

  //Alternate color text
  let txt = $(".alternate-color-text");
  if(txt.length>0){
    let txt_words = txt.text().match(/\b(\w+)\b/g);
    let txt_html = '';
    for (var i = 0; i <= txt_words.length - 1; i++) {
      txt_html += '<span>'+txt_words[i]+'</span> ';
    }
    window.console.log(txt_html);
    txt.html(txt_html);
  }


  //Fixed navigatino on vph scroll
  $(window).scroll(function() {    
      var scroll = $(window).scrollTop();
      var h = $(window).height();

      if (scroll >= h) {
        $("#wrapper-navbar").addClass("sticky");
      } else {
        $("#wrapper-navbar").removeClass("sticky");

      }
  });
 
  //Activate tooltips
  var tooltipList1 = [].slice.call(document.querySelectorAll('[data-bs-toggle = "tooltip"]'))  
  var tooltipList2 = tooltipList1.map(function (tooltipTriggerfun) {  
    return new Tooltip(tooltipTriggerfun)  
  })  

  //open sector info panels

  $('#main-nav [data-target]').hover(function () {
    let $target = $(this).data('target');
    let $t_id = $target.split("-")[1];
    $($target).siblings().removeClass("show");
    $($target).addClass("show");
    $(this).addClass("active");
    $(this).parent().siblings().find(".dropdown-item").removeClass("active");
  }, function () {

  })

  //Hide Info panel when clicking other menu
  $('#main-nav a').on('click', function () {
    $('.menu-sectors .sector-info-panel-item').removeClass('show');
  })


})(jQuery);

//Data target and actions
(function ($) {
  //Scroll actions
  $('[data-action]').click(function (e) {
      e.preventDefault();
      var $target = $(this).data('target');
      //alert($target );
      let $action = $(this).data('action');
      if ($action == 'scroll') {
        $('html,body').animate({
          scrollTop: $($target).offset().top
        },  1000); 
      }
    })
    .css({ 
      cursor: 'pointer'
    });

})(jQuery);

//Parallax
//Simple parallax
import simpleParallax from 'simple-parallax-js';
(function ($) {
  //Hero parallax
  var image_hero = document.getElementsByClassName('full_background_image__fore');
  new simpleParallax(image_hero,{
    //scale: 1,
    delay: .6,
    //overflow : true,
	  transition: 'cubic-bezier(0,0,0,1)'
  });
  //Parallax class
  var image_parallax = document.getElementsByClassName('parallax');
  new simpleParallax(image_parallax, {
    //scale: 1.5,
    delay: .6,
	  transition: 'cubic-bezier(0,0,0,1)'
  });
})(jQuery);


//Init swiper for modal gallery
const swiper_modal = new Swiper('.swiper-modal', {
  //modules: [Navigation, Lazy, EffectFade],
  //spaceBetween: 100,
  lazy: true,
  autoheight: true,
  slidesPerView: 1,
  //centeredSlides: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  navigation: {
    nextEl: '.swiper-gallery-button-next',
    prevEl: '.swiper-gallery-button-prev',
  },
});

swiper_modal.on('slideChange', function () {
  //console.log('slide changed');
});

 /*
  //Image modals
  */
  (function ($) {
  let modal_imgs = $('[data-image-modal]');
  if(modal_imgs.length>0){
    let image_modal = new Modal(document.getElementById('modal-gallery-image'))
    modal_imgs.on('click' , function(){
      let url = $(this).data('image-modal');
      let index = $(this).data('image-index');

      image_modal.show();
      swiper_modal.slideTo(index);
      //swiper_modal.slideNext(3000);
      // swiper_modal.navigation.destroy();
      // swiper_modal.navigation.init();


      //swiper_modal.lazy.load()
      // let srcset = $(this).data('image-modal-srcset');
      // $('.modal-image').attr('src' , url);
      // $('.modal-image').attr('srcset' , srcset);
      
    });
  }
}(jQuery));


(function($) {
  $('.form--hide-on-load input').on('focus' , function(){
    $('.form--hide-on-load .hide-on-load').addClass('show');
  });
}(jQuery));
